.animate-charcter
{
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  display: inline-block;
      font-size: 30px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.marquee-container {
  overflow: hidden;
  position: relative;
}

.marquee-container::before {
  content: '\00a0';
}

.scrolling {
  animation: marquee 20s linear infinite;
  display: block;
  min-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

@keyframes marquee {
  from {
    transform: translateX(20%);
  }
  to { 
    transform: translateX(-100%);
  }
}